import { Component, OnInit, AfterViewChecked, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-site-menu',
  templateUrl: './site-menu.component.html',
  styleUrls: ['./site-menu.component.scss']
})
export class SiteMenuComponent implements OnInit, OnDestroy {
  @Input() circleImageUrl: string;
  @Input() animation: boolean = false;

  menuActive: boolean = false;
  menuAnimation: boolean = false;
  language: string;
  langSubscription: any;
  showContact: boolean = false;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

  public setLanguage(langKey: string) {
    this.translate.use(langKey);
  }

  public toggleMenu() {
    this.menuActive = !this.menuActive;
    if (this.menuActive) {
      document.querySelector('body').classList.add('no-scroll');
      
      // trigger animation when opening menu
      setTimeout(() => {
        this.menuAnimation = true;
      }, 1);
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }

  public closeMenu() {
    setTimeout(() => {
      this.menuActive = false;
      this.menuAnimation = false;
      document.querySelector('body').classList.remove('no-scroll');
    }, 100);
  }

  public closeContact() {
    this.showContact = false;
    this.closeMenu();
  }

}
