import { Component, OnInit } from "@angular/core";
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieConsent } from 'cookieconsent';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  param: any;
  title = "weingutBraun";

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
     // this language will be used as a fallback when a translation isn't found in the current language
     translate.setDefaultLang('de');

     // the lang to use, if the lang isn't available, it will use the current loader to get them
     translate.use('de');
  }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      document.querySelector("body").classList.remove("no-scroll");
    });

    this.setLanguage();

    let path = window.location.pathname.split( '/' )[2];
    if (path !== 'preisliste' && path !== 'expertise') {
      this.initCookieConsent();
    }
  }

  private setLanguage() {
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.param = val.state.root.firstChild.params.langParam;
        // if (this.param !== 'de') {
        //   this.router.navigate(['/']);
        // } else {
        //   this.translate.use(this.param);
        // }
        if (this.param !== 'de' && this.param !== 'en') {
          this.router.navigate(['/']);
        } else {
          this.translate.use(this.param);
        }
      }
    });
  }

  private initCookieConsent() {
    setTimeout(() => {
      (<any>window).cookieconsent.initialise({
        palette: {
          popup: {
            background: "#7D7672"
          },
          button: {
            background: "#DBD3CB",
            text: "#000"
          }
        },
        theme: "classic",
        position: "bottom-left",
        content: {
          message: (() => {
            if (this.param === 'en') {
              return "Diese Webseite verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Durch die Nutzung unserer Webseite erklären Sie sich mit der Nutzung von Cookies einverstanden."
            }
            return "Diese Webseite verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Durch die Nutzung unserer Webseite erklären Sie sich mit der Nutzung von Cookies einverstanden.";
          })(),
          dismiss: "Ich stimme zu",
          link: (() => {
            if (this.param === 'en') {
              return "Weitere Informationen";
            }
            return "Weitere Informationen";
          })(),
          href: `https://braun-wein-sekt.de/${this.param}/datenschutz`
        }
      });
    }, 100);
  }
}
