import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WeingutComponent } from './weingut/weingut.component';
import { TermineComponent } from './termine/termine.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { MediaKitComponent } from './media-kit/media-kit.component';
import { AktuellesComponent } from './aktuelles/aktuelles.component';
import { WeineComponent } from './weine/weine.component';
import { WeinarchivComponent } from './weinarchiv/weinarchiv.component';
import { PdfExpertiseComponent } from './pdf-expertise/pdf-expertise.component';
import { PdfPreislisteComponent } from './pdf-preisliste/pdf-preisliste.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { WeinpaketComponent } from './weinpaket/weinpaket.component';
import { SommerfestComponent } from './sommerfest/sommerfest.component';
import { TdotComponent } from './tdot/tdot.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'de',
    pathMatch: 'full'
  },
  {
    path: ':langParam',
    component: WeingutComponent
  },
  {
    path: ':langParam/weine',
    component: WeineComponent
  },
  {
    path: ':langParam/weinarchiv',
    component: WeinarchivComponent
  },
  {
    path: ':langParam/termine',
    component: TermineComponent
  },
  {
    path: ':langParam/aktuelles',
    component: AktuellesComponent
  },
  {
    path: ':langParam/datenschutz',
    component: DatenschutzComponent
  },
  {
    path: ':langParam/impressum',
    component: ImpressumComponent
  },
  {
    path: ':langParam/media-kit',
    component: MediaKitComponent
  },
  {
    path: ':langParam/expertise',
    component: PdfExpertiseComponent
  },
  {
    path: ':langParam/preisliste',
    component: PdfPreislisteComponent
  },
  {
    path: ':langParam/newsletter',
    component: NewsletterComponent
  },
  {
    path: ':langParam/weinpaket/:bundleSlug',
    component: WeinpaketComponent
  },
  {
    path: ':langParam/sommerfest',
    component: SommerfestComponent
  },
  {
    path: ':langParam/tdot',
    component: TdotComponent
  },
  {
    path: '**',
    redirectTo: 'de',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
