import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
// import { ApiResponse } from "../interfaces/api-response";
// import _ from "lodash";
import { Router, ActivatedRoute } from '@angular/router';
// import slugify from 'slugify';

@Component({
  selector: 'app-sommerfest',
  templateUrl: './sommerfest.component.html',
  styleUrls: ['./sommerfest.component.scss']
})
export class SommerfestComponent implements OnInit {
  sommerfestForm: FormGroup;
  language: string;
  circleImageUrl: string;
  langSubscription: any;
  sendingMail: boolean = false;
  mailError: boolean = false;
  mailSuccess: boolean = false;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getLanguage();
    this.createForm();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.circleImageUrl = `/assets/images/site/_${
      this.language
    }-circle-menu.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.circleImageUrl = `/assets/images/site/_${
          this.language
        }-circle-menu.svg`;
      }
    });
  }

  private createForm() {
    this.sommerfestForm = this.fb.group({
      guestsNumber: [""],
      guestsNames: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      notes: [""],
      takingPartWedding: [false],
      takingPartGetTogether: [false],
      takingPartSommerfest: [false],
      takingPartFruehschoppen: [false],
    });
  }

  public submitForm() {
    // console.log(this.cartQuantity, this.sommerfestForm);
    this.sommerfestForm.markAsDirty();

    if (this.sommerfestForm.valid) {
      this.sendingMail = true;
      this.mailError = false;

      let sendModel = this.sommerfestForm.value;

      let httpSendOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      this.http
        .post(
          "/assets/mail/sommerfest-mail.php",
          // "http://formtest-braun.local/sommerfest-mail.php",
          sendModel,
          httpSendOptions
        )
        .subscribe(
          res => {
            this.mailSuccess = true;
            this.sommerfestForm.disable();
          },
          error => {
            console.log(error);
            this.sendingMail = false;
            this.mailError = true;
          }
        );
    }
  }

}
