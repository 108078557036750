import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import slugify from 'slugify';

@Component({
  selector: 'app-tdot',
  templateUrl: './tdot.component.html',
  styleUrls: ['./tdot.component.scss']
})
export class TdotComponent implements OnInit, OnDestroy {
  cmftData: any;
  liveVideoIsLive = false;
  liveVideoCheckInterval: any;
  rawVideoIframe: string;
  liveVideoIframe: any;
  modalActive = false;
  modalVideoID: string;
  activeVideo = {
    index: 0
  };
  videos = [
    {
      timeStamp: 1592676000,
      text: '18:00 Begrüßung'
    },
    {
      timeStamp: 1592676900,
      text: '18:15 Verkostung Breites Spektrum'
    },
    {
      timeStamp: 1592678700,
      text: '18:45 Verkostung Chardonnay Vertikale'
    },
    {
      timeStamp: 1592680500,
      text: '19:15 Verkostung Spätburgunder Vertikale'
    },
    {
      timeStamp: 1592682300,
      text: '19:45 Verkostung Riesling Vertikale'
    },
    {
      timeStamp: 1592683200,
      text: '20:00 Tagesausklang'
    },
  ];
  agendaInterval: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) { }

  private fetchContent() {
    // fetch news
    this.http.get<any>(`https://api.cmft.io/v1/weingutBraun/alias/tagDerOffenenTr?embedAssets=true&includes=1`, this.httpOptions).subscribe(res => {
      _.map(res.fields.rieslingVertikale, (wine, index) => {
        const _wine =  _.find(res.includes.wein, { meta: {id: wine.meta.id }});
        res.fields.rieslingVertikale[index] = _wine;
        _wine.pdfFilename = slugify(`${_wine.fields.jahrgang}-${_wine.fields.weinname}-${_wine.fields.geschmack}-${_wine.fields.artikelnummer}`, { lower: true});
      });
      _.map(res.fields.sptburgunderVertikale, (wine, index) => {
        const _wine =  _.find(res.includes.wein, { meta: {id: wine.meta.id }});
        res.fields.sptburgunderVertikale[index] = _wine;
        _wine.pdfFilename = slugify(`${_wine.fields.jahrgang}-${_wine.fields.weinname}-${_wine.fields.geschmack}-${_wine.fields.artikelnummer}`, { lower: true});
      });
      _.map(res.fields.chardonnayVertikale, (wine, index) => {
        const _wine =  _.find(res.includes.wein, { meta: {id: wine.meta.id }});
        res.fields.chardonnayVertikale[index] = _.find(res.includes.wein, { meta: {id: wine.meta.id }});
        _wine.pdfFilename = slugify(`${_wine.fields.jahrgang}-${_wine.fields.weinname}-${_wine.fields.geschmack}-${_wine.fields.artikelnummer}`, { lower: true});
      });
      _.map(res.fields.breitesSpektrum, (wine, index) => {
        const _wine =  _.find(res.includes.wein, { meta: {id: wine.meta.id }});
        res.fields.breitesSpektrum[index] = _.find(res.includes.wein, { meta: {id: wine.meta.id }});
        _wine.pdfFilename = slugify(`${_wine.fields.jahrgang}-${_wine.fields.weinname}-${_wine.fields.geschmack}-${_wine.fields.artikelnummer}`, { lower: true});
      });

      this.cmftData = res;
    });
  }

  private initVideoStatusInterval() {
    this.checkLiveVideoStatus();
    // this.liveVideoCheckInterval = setInterval(() => {
    //   this.checkLiveVideoStatus();
    // }, 5000);
  }

  private checkLiveVideoStatus() {
    this.http.get<any>(`https://api.cmft.io/v1/weingutBraun/alias/tagDerOffenenTr?fields=fields(liveVideo)`, this.httpOptions).subscribe(res => {
      // console.log(this.rawVideoIframe, res.fields.liveVideo.iFrameCode);
      if (!this.rawVideoIframe || this.rawVideoIframe !== res.fields.liveVideo.iFrameCode) {
        const _iframeCode: any = this.sanitizer.bypassSecurityTrustHtml(res.fields.liveVideo.iFrameCode);
        this.rawVideoIframe = res.fields.liveVideo.iFrameCode;
        this.liveVideoIframe = _iframeCode;
      }

      if (res.fields.liveVideo.status.length && res.fields.liveVideo.status[0] === 'videoIsLive') {
        this.liveVideoIsLive = true;
      } else {
        this.liveVideoIsLive = false;
      }
    });
  }

  private determineActiveAgendaItem() {
    this.agendaInterval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      // console.log(currentTime);
      let activeItemIndex: number;

      _.map(this.videos, (video, index) => {
        if (video.timeStamp <= currentTime) {
          activeItemIndex = index;
        }
      });

      this.activeVideo.index = activeItemIndex;

      // console.log(this.activeVideo);
    }, 1000);
  }

  private watchEscKey() {
    document.onkeydown = (evt: any) => {
      evt = evt || window.event;
      let isEscape = false;
      if ('key' in evt) {
          isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
          if (this.modalActive) {
            this.modalActive = false;
          }
      }
    };
  }

  public initModal(modalName: string) {
    console.log(modalName);
    this.modalVideoID = modalName;
    this.modalActive = true;
  }

  public closeModal() {
    // console.log('close');
    this.modalActive = false;
    this.modalVideoID = '';
  }

  public selectVideo(itemIndex) {
    // console.log(itemIndex);
    // this.activeVideo.index = itemIndex;
  }

  ngOnInit() {
    document.querySelector('body').classList.add('tdot');
    this.fetchContent();
    this.initVideoStatusInterval();
    this.determineActiveAgendaItem();
    this.watchEscKey();
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('tdot');
    window.clearInterval(this.agendaInterval);
    document.onkeydown = null;
  }

}
