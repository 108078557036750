import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-site-newsletter-popup',
  templateUrl: './site-newsletter-popup.component.html',
  styleUrls: ['./site-newsletter-popup.component.scss']
})
export class SiteNewsletterPopupComponent implements OnInit, OnDestroy {
  @Input() options: any;
  @Output() close = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.setScrollBlock();
  }

  ngOnDestroy() {
    if (this.options && this.options.unsetScrollBlock ) {
      this.unsetScrollBlock();
    }
  }

  private setScrollBlock() {
    document.querySelector('body').classList.add('no-scroll');
  }

  private unsetScrollBlock() {
    document.querySelector("body").classList.remove("no-scroll");
  }

  public closeFn() {
    this.close.emit('close');
  }

}
