import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiResponse } from "../../interfaces/api-response";
import _ from "lodash";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";

@Component({
  selector: "app-weinanfrage",
  templateUrl: "./weinanfrage.component.html",
  styleUrls: ["./weinanfrage.component.scss"]
})
export class WeinanfrageComponent implements OnInit, OnDestroy {
  overlayActive: boolean = false;
  circleImageUrl: string;
  quantity: number = 0;
  checkQtyInterval: any;
  language: string;
  langSubscription: any;
  wineData: any[] = [];
  cart: any;
  inquiryForm: FormGroup;
  sendingMail: boolean = false;
  mailError: boolean = false;
  mailSuccess: boolean = false;
  minBundleSize: number = 12; // the smallest number of one bundle, e.g. 6 bottles per box
  displayValidationMessages: boolean = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi" // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.checkQtyInterval = setInterval(() => {
      this.getTotal();
    }, 500);

    this.getLanguage();
    this.createForm();
    this.getTotal();
  }

  ngOnDestroy() {
    clearInterval(this.checkQtyInterval);
  }

  /*
   * Save changed amounts to local storage
   */
  private updateCart() {
    localStorage.setItem("cart", JSON.stringify(this.cart));
    this.getTotal();
  }

  private getTotal() {
    let _qty = 0;
    let _cart = JSON.parse(localStorage.getItem("cart"));

    if (_cart) {
      _cart.map(val => {
        _qty += val.quantity;
      });

      if (_qty !== this.quantity) {
        this.quantity = _qty;
        this.inquiryForm.controls['numberOfBottles'].setValue(this.quantity);
      }
    }
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.circleImageUrl = `/assets/images/site/_${
      this.language
    }-circle-weinanfrage.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.circleImageUrl = `/assets/images/site/_${
          this.language
        }-circle-weinanfrage.svg`;
      }
    });
  }

  private fetchContent() {
    let docIdList = (() => {
      this.cart = JSON.parse(localStorage.getItem("cart"));
      let list = [];
      this.cart.map(val => {
        list.push(val.id);
      });

      return list;
    })();

    const query = encodeURIComponent(
      JSON.stringify({
        filters: [
          {
            "*.meta.id": {
              in: docIdList
            }
          }
        ],
        fields:
          "fields(weinname,jahrgang,geschmack,inhalt,alkoholgehalt,artikelnummer,klassifikation,lage),meta(id)"
      })
    );

    this.http
      .get<ApiResponse>(
        `https://api.cmft.io/v1/weingutBraun/documents?locale=${
          this.language
        }&query=${query}&embedAssets=true`,
        this.httpOptions
      )
      .subscribe(res => {
        // fill wine data with response data, but maintain the cart order
        this.wineData = [];
        this.cart.map(product => {
          // console.log(product);

          let _prod = _.filter(res.data, ["meta.id", product.id]);
          // console.log(_prod[0]);

          this.wineData.push(_prod[0]);
        });

        // console.log(this.wineData);
      });
  }

  private createForm() {
    this.inquiryForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      street: ["", Validators.required],
      zip: ["", Validators.required],
      city: ["", Validators.required],
      phone: [""],
      email: ["", [Validators.required, Validators.email]],
      newsletterOptIn: [false],
      message: [""],
      numberOfBottles: [0, [Validators.required, this.bundleSizeValidator.bind(this)]]
    });
  }

  private bundleSizeValidator(control: FormControl) {
    let _currentSize = control.value;

    if (_currentSize <= 6) {
      if (_currentSize % 6 !== 0) {
        const _bundleSizeValidator = {
          valid: false
        };

        return _bundleSizeValidator;
      }
    } else {
      if (_currentSize % this.minBundleSize !== 0) {
        const _bundleSizeValidator = {
          valid: false
        };

        return _bundleSizeValidator;
      }
    }

    return null;
  }

  public showOverlay() {
    this.fetchContent();
    this.mailSuccess = false;
    this.overlayActive = true;
    document.querySelector("body").classList.add("no-scroll");
  }

  public increaseQty(index: number) {
    let num = parseInt(this.cart[index].quantity);
    num++;
    this.cart[index].quantity = num;
    this.updateCart();
  }

  public decreaseQty(index: number) {
    if (this.cart[index].quantity > 1) {
      let num = parseInt(this.cart[index].quantity);
      num--;
      this.cart[index].quantity = num;
      this.updateCart();
    }
  }

  public submitForm() {
    console.log(this.inquiryForm);
    this.inquiryForm.markAsDirty();
    this.displayValidationMessages = true;

    if (this.inquiryForm.valid) {
      this.sendingMail = true;
      this.mailError = false;

      let _cart = [];

      // aggregate the product data for the mail template
      this.cart.map(cartItem => {
        let _product = _.filter(this.wineData, ["meta.id", cartItem.id]);
        let _cartProduct = {
          id: cartItem.id,
          quantity: cartItem.quantity,
          product: _product[0].fields
        };

        _cart.push(_cartProduct);
      });

      let sendModel = {
        customer: this.inquiryForm.value,
        cart: _cart,
        language: this.language
      };

      let httpSendOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      this.http
        .post(
          // "http://formtest-braun.local/inquiry-mail.php",
          "/assets/mail/inquiry-mail.php",
          sendModel,
          httpSendOptions
        )
        .subscribe(
          res => {
            // If successfull, send out the customer mail
            this.http
              .post(
                // "http://formtest-braun.local/inquiry-mail-customer.php",
                "/assets/mail/inquiry-mail-customer.php",
                sendModel,
                httpSendOptions
              )
              .subscribe(
                res => {
                  // console.log(res);
                  this.mailSuccess = true;
                  this.cart = [];
                  this.updateCart();
                },
                error => {
                  console.log(error);
                  this.sendingMail = false;
                  this.mailError = true;
                }
              );
          },
          error => {
            console.log(error);
            this.sendingMail = false;
            this.mailError = true;
          }
        );
    }
  }

  public closeFn() {
    this.overlayActive = false;
    document.querySelector("body").classList.remove("no-scroll");
  }

  public removeProduct(index: number) {
    this.cart.splice(index, 1);
    this.wineData.splice(index, 1);
    // console.log(index, this.cart, this.wineData);

    this.updateCart();
  }
}
