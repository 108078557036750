import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import * as moment  from 'moment';
import _ from 'lodash';
import { nextTick } from 'q';
import { Identifiers } from '@angular/compiler';

@Component({
  selector: 'app-pdf-preisliste',
  templateUrl: './pdf-preisliste.component.html',
  styleUrls: ['./pdf-preisliste.component.scss']
})
export class PdfPreislisteComponent implements OnInit {
  language: string;
  langSubscription: any;
  wine: any;
  classifications: any;
  events: any;
  eventList = {};
  news: any;
  listType: string;

  WineCategories = {
    edelsuess: {
      headline: "Edelsüß",
      identifier: 'edelsuess'
    },
    rose: {
      headline: "Rosé",
      identifier: 'rose'
    },
    rot: {
      headline: "Rot",
      identifier: 'rot'
    },
    secco: {
      headline: "Secco",
      identifier: 'secco'
    },
    sekt: {
      headline: "Sekt",
      identifier: 'sekt'
    },
    weiss: {
      headline: "Weiss",
      identifier: 'weiss'
    },
    weissRestsuess: {
      headline: "Weiss Restsüß",
      identifier: 'weissRestsuess'
    },
    spektrum: {
      headline: "Spektrum Cuvée",
      identifier: 'spektrum'
    }
  };

  EventCategories = [
    {
      identifier: "imHof",
      title: "Im Hof",
    },
    // merged with imHof
    // {
    //   identifier: "ausserHaus",
    //   title: "Ausser Haus",
    // },
    {
      identifier: "messen",
      title: "Messen"
    },
  ];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getLanguage();
    document.querySelector("body").classList.add("pdf");
    this.listType = window['pdf'] && window['pdf']['customParam1'] ? window['pdf']['customParam1'] : 'evp';
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    moment.locale(this.language);
    this.fetchContent();
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        moment.locale(this.language);
        this.fetchContent();
      }
    });
  }

  private fetchContent() {
    this.fetchClassifications();
    this.fetchEvents();
    this.fetchNews();
  }

  private fetchClassifications() {
    return this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/klassifikationen?locale=${this.language}&embedAssets=true&limit=250`, this.httpOptions).subscribe(res => {
      this.classifications = res.data;
    })
  }

  private fetchEvents() {
    return this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/termine?locale=${this.language}&embedAssets=true&limit=250`, this.httpOptions).subscribe(res => {
      this.events = res.data;
      _.map(this.events, (event) => {
          let category = event.fields.kategorie;

          // merge ausserHaus with imHof
          if (category === 'ausserHaus') {
            category = 'imHof';
          }

          this.eventList[category] = this.eventList[category] || [];

          if (this.eventList[category] && this.eventList[category].length <= 3) {
            // replace event text
            event.fields.text.replaced = event.fields.text.html.replace(/<br\s*[\/]?>/gi, '');

            this.eventList[category].push(event);
          }
      })
    })
  }

  private fetchNews() {
    return this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/news?locale=${this.language}&embedAssets=true&limit=250`, this.httpOptions).subscribe(res => {
      this.news = res.data;
    })
  }

}
