import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WeingutComponent } from './weingut/weingut.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';

import { NgInviewModule } from 'angular-inport';
import { SiteLogoComponent } from './site-logo/site-logo.component';
import { SiteMenuComponent } from './site-menu/site-menu.component';
import { SiteKontaktComponent } from './site-kontakt/site-kontakt.component';
import { SiteIntroAnimationComponent } from './site-intro-animation/site-intro-animation.component';
import { TermineComponent } from './termine/termine.component';
import { SiteNewsletterPopupComponent } from './site-newsletter-popup/site-newsletter-popup.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { MediaKitComponent } from './media-kit/media-kit.component';
import { AktuellesComponent } from './aktuelles/aktuelles.component';
import { WeineComponent } from './weine/weine.component'; 
import { SiteWeinPopupComponent } from './site-wein-popup/site-wein-popup.component';
import { WeinanfrageComponent } from './weine/weinanfrage/weinanfrage.component';
import { WeinarchivComponent } from './weinarchiv/weinarchiv.component';
import { SiteBackToTopComponent } from './site-back-to-top/site-back-to-top.component';
import { PdfExpertiseComponent } from './pdf-expertise/pdf-expertise.component';
import { PdfPreislisteComponent } from './pdf-preisliste/pdf-preisliste.component';
import { PdfKlassifikationComponent } from './pdf-preisliste/pdf-klassifikation/pdf-klassifikation.component';
import { PdfWeineComponent } from './pdf-preisliste/pdf-klassifikation/pdf-weine/pdf-weine.component';
import { MomentModule } from 'ngx-moment';
import { ClickOutsideModule } from 'ng-click-outside';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { WeinpaketComponent } from './weinpaket/weinpaket.component';
import { SommerfestComponent } from './sommerfest/sommerfest.component';
import { TdotComponent } from './tdot/tdot.component';
import { TdotButtonComponent } from './tdot-button/tdot-button.component';
import { TerminButtonComponent } from './termin-button/termin-button.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    WeingutComponent,
    SiteFooterComponent,
    SiteLogoComponent,
    SiteMenuComponent,
    SiteKontaktComponent,
    SiteIntroAnimationComponent,
    TermineComponent,
    SiteNewsletterPopupComponent,
    ImpressumComponent,
    DatenschutzComponent,
    MediaKitComponent,
    AktuellesComponent,
    WeineComponent,
    SiteWeinPopupComponent,
    WeinanfrageComponent,
    WeinarchivComponent,
    SiteBackToTopComponent,
    PdfExpertiseComponent,
    PdfPreislisteComponent,
    PdfKlassifikationComponent,
    PdfWeineComponent,
    SanitizeHtmlPipe,
    NewsletterComponent,
    WeinpaketComponent,
    SommerfestComponent,
    TdotComponent,
    TdotButtonComponent,
    TerminButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgInviewModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    ClickOutsideModule,
    FilterPipeModule,
    TruncateModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
