import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  circleImageUrl: string;
  language: string;
  langSubscription: any;
  langSubscriptionMeta: any;

  constructor(
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.getLanguage();
    this.setTitle();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.circleImageUrl = `/assets/images/site/_${this.language}-circle-impressum.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
      this.circleImageUrl = `/assets/images/site/_${this.language}-circle-impressum.svg`;
    });
  }

  private setTitle() {
    this.langSubscriptionMeta = this.translate.get('pageTitles.impressum').subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res});
    });
  }

}
