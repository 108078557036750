import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-site-logo',
  templateUrl: './site-logo.component.html',
  styleUrls: ['./site-logo.component.scss']
})
export class SiteLogoComponent implements OnInit {
  logoVisible: boolean = false;

  constructor() { }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    if ($event.target && $event.target.scrollingElement && $event.target.scrollingElement.scrollTop > 50) {
      this.showLogo();
    } else if ($event.path && $event.path[1].scrollY > 50) {
      this.showLogo();
    } else if ($event.pageY && $event.pageY > 50) {
      this.showLogo();
    }
  }

  ngOnInit() {
  }

  private showLogo() {
    this.logoVisible = true;
  }

}
