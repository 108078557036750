import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-weine',
  templateUrl: './weine.component.html',
  styleUrls: ['./weine.component.scss']
})
export class WeineComponent implements OnInit {
  circleImageUrl: string;
  triggerAnimation: boolean = false; // boolean is set to toggle class and trigger css animation
  language: string;
  langSubscription: any;
  langSubscriptionMeta: any;
  classifications:any = {};
  haendler: any[] = [];
  countries: any[] = [];
  highestHaendler: number = 0;
  showWeinPopup: boolean = false;
  weinPopupWeinId: string;
  cart: any[] = [];
  cartTotalItems: number = 0;
  wineListForm: FormGroup;
  sendingMail: boolean = false;
  mailError: boolean = false;
  mailSuccess: boolean = false;

  // weine
  literWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    rose: [],
  };
  alltagWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    rose: [],
    spektrum: [],
    secco: []
  };
  individuellWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    rose: [],
    sekt: []
  };
  unikatWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    rose: [],
    edelsuess: [],
  };
  prickelndWeine = {
    sectionActive: false,
    secco: [],
    sekt: []
  };
  haendlerSection = {
    sectionActive: false,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private fb: FormBuilder,
    private title: Title,
    private meta: Meta,
  ) {}

  ngOnInit() {
    this.getLanguage();
    this.setTitle();
    this.createForm();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.fetchContent();
    this.circleImageUrl = `/assets/images/content/_${this.language}-circle-weine.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.fetchContent();
        this.circleImageUrl = `/assets/images/content/_${this.language}-circle-weine.svg`;
      }
    });
  }

  private setTitle() {
    this.langSubscriptionMeta = this.translate.get('pageTitles.weine').subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res});
    });
  }

  private fetchContent() {

    // fetch klassifikationen

    const klassifikationenQuery = encodeURIComponent(JSON.stringify({
      "fields": "fields(identifier,name,beschreibung)",
    }));

    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/klassifikationen?query=${klassifikationenQuery}&locale=${this.language}`, this.httpOptions).subscribe(res => {
      this.classifications.einLiter = _.filter(res.data, { fields: { identifier: "einLiter"}})[0].fields;
      this.classifications.alltag = _.filter(res.data, { fields: { identifier: "alltag"}})[0].fields;
      this.classifications.individuell = _.filter(res.data, { fields: { identifier: "individuell"}})[0].fields;
      this.classifications.unikat = _.filter(res.data, { fields: { identifier: "unikat"}})[0].fields;
      this.classifications.prickelnd = _.filter(res.data, { fields: { identifier: "prickelnd"}})[0].fields;
      // console.log(this.classifications, res.data);
    });

    /*
     * Fetch weine
     */

    const wineQuery = encodeURIComponent(JSON.stringify({
      "limit": 1000,
      "fields": "fields(weinname,klassifikation,kategorie,jahrgang,geschmack,verfuegbarkeit,lage),meta(id)",
      "filters": [
        {
          "*.fields.verfuegbarkeitVal": {
            "notIn": "archiv"
          }
        },
        {
          "and": {
            "*.fields.anzeige": {
              "in": "website"
            }
          }
        }
      ]
    }));

    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/weine?locale=${this.language}&query=${wineQuery}`, this.httpOptions).subscribe(res => {
      // console.log(res);

      // liter
      this.literWeine.weiss = _.filter(res.data, { fields: { klassifikation: "einLiter", kategorie: "weiss"}});
      this.literWeine.weissRestsuess = _.filter(res.data, { fields: { klassifikation: "einLiter", kategorie: "weissRestsuess"}});
      this.literWeine.rot = _.filter(res.data, { fields: { klassifikation: "einLiter", kategorie: "rot"}});
      this.literWeine.rose = _.filter(res.data, { fields: { klassifikation: "einLiter", kategorie: "rose"}});
      // console.log(this.literWeine);

      // alltag
      this.alltagWeine.weiss = _.filter(res.data, { fields: { klassifikation: "alltag", kategorie: "weiss"}});
      this.alltagWeine.weissRestsuess = _.filter(res.data, { fields: { klassifikation: "alltag", kategorie: "weissRestsuess"}});
      this.alltagWeine.rot = _.filter(res.data, { fields: { klassifikation: "alltag", kategorie: "rot"}});
      this.alltagWeine.rose = _.filter(res.data, { fields: { klassifikation: "alltag", kategorie: "rose"}});
      // this.alltagWeine.secco = _.filter(res.data, { fields: { klassifikation: "prickelnd", kategorie: "secco"}});
      this.alltagWeine.spektrum = _.filter(res.data, { fields: { klassifikation: "alltag", kategorie: "spektrum"}});
      // console.log(this.alltagWeine);

      // individuell
      this.individuellWeine.weiss = _.filter(res.data, { fields: { klassifikation: "individuell", kategorie: "weiss"}});
      this.individuellWeine.weissRestsuess = _.filter(res.data, { fields: { klassifikation: "individuell", kategorie: "weissRestsuess"}});
      this.individuellWeine.rot = _.filter(res.data, { fields: { klassifikation: "individuell", kategorie: "rot"}});
      this.individuellWeine.rose = _.filter(res.data, { fields: { klassifikation: "individuell", kategorie: "rose"}});
      this.individuellWeine.sekt = _.filter(res.data, { fields: { klassifikation: "prickelnd", kategorie: "sekt"}});
      // console.log(this.individuellWeine);

      // unikat
      this.unikatWeine.weiss = _.filter(res.data, { fields: { klassifikation: "unikat", kategorie: "weiss"}});
      this.unikatWeine.weissRestsuess = _.filter(res.data, { fields: { klassifikation: "unikat", kategorie: "weissRestsuess"}});
      this.unikatWeine.rot = _.filter(res.data, { fields: { klassifikation: "unikat", kategorie: "rot"}});
      this.unikatWeine.rose = _.filter(res.data, { fields: { klassifikation: "unikat", kategorie: "rose"}});
      this.unikatWeine.edelsuess = _.filter(res.data, { fields: { klassifikation: "unikat", kategorie: "edelsuess"}});
      // console.log(this.unikatWeine);

      // secco
      this.prickelndWeine.secco = _.filter(res.data, { fields: { kategorie: "secco"}});
      this.prickelndWeine.sekt = _.filter(res.data, { fields: { kategorie: "sekt"}});
      // console.log(this.prickelndWeine);
    });

    // fetch bezugsquellen
    const haendlerQuery = encodeURIComponent(JSON.stringify({
      "limit": 1000,
    }));

    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/haendler?locale=${this.language}&query=${haendlerQuery}`, this.httpOptions).subscribe(res => {
      // console.log(res.data);

      this.countries = _.uniq(_.map(res.data, (item) => { return item.fields.geographie; }));
      // 'de' must be first
      let _deIndex = this.countries.indexOf('de');
      let arrayMove = (x, from, to) => {
        x.splice((to < 0 ? x.length + to : to), 0, x.splice(from, 1)[0]);
      }
      arrayMove(this.countries, _deIndex, 0);

      this.haendler.push(...res.data);
      // this.equalizeHaendler();
    });
  }

  /*
  * find the largest (height) handler item and
  * set this height for all the others
  */
  private equalizeHaendler() {
    let haendler = document.querySelectorAll('.haendler-item');

    // find the highest element
    _.map(haendler, el => {
      if (el.clientHeight > this.highestHaendler) {
        this.highestHaendler = el.clientHeight;
      }
    });

    // apply the height for all elements
    setTimeout(() => {
      _.map(haendler, el => {
        el.style.height = this.highestHaendler.toString() + 'px';
      });
    }, 5);
  }

  private createForm() {
    this.wineListForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      newsletterOptIn: [false]
    });
  }

  public inview(event) {
    let newCircleImage: string;

    if (event.data && event.data.name) {
      switch (event.data.name) {
        case 'weine':
        newCircleImage = `/assets/images/content/_${this.language}-circle-weine.svg`;
          break;
        case 'liter':
        newCircleImage = `/assets/images/content/_de-circle-liter.svg`;
          break;
        case 'alltag':
        newCircleImage = `/assets/images/content/_de-circle-alltag.svg`;
          break;
        case 'individuell':
        newCircleImage = `/assets/images/content/_de-circle-individuell.svg`;
          break;
        case 'unikat':
        newCircleImage = `/assets/images/content/_de-circle-unikat.svg`;
          break;
        case 'prickelnd':
        newCircleImage = `/assets/images/content/_de-circle-prickelnd.svg`;
          break;
        case 'haendler':
        newCircleImage = `/assets/images/content/_${this.language}-circle-haendler.svg`;
          break;
        case 'preisliste':
        newCircleImage = `/assets/images/content/_${this.language}-circle-preisliste.svg`;
          break;
        // missing default is intentional! keep the current image if name is undefined
      }

      if (newCircleImage !== this.circleImageUrl) {
        this.circleImageUrl = newCircleImage;

        // trigger the animation
        this.triggerAnimation = true;
        setTimeout(() => {
          this.triggerAnimation = false;
        }, 1000);
      }
    }
  }

  /*
  * Sets a css section height to enable animation
  * */
  private setWineSectionHeight(sectionName) {
    let element = <HTMLElement>document.querySelector(`.wine-section.${sectionName} .wine-list`);
    if (!this[sectionName].sectionActive) {
      element.style.maxHeight = null;
    } else {
      element.style.maxHeight = element.scrollHeight + 'px';
    }
  }

  public collapseSection(sectionName: string) {
    // setTimeout to avoid colliding with expandSection()
    setTimeout(() => {
      this[sectionName].sectionActive = false;
      this.setWineSectionHeight(sectionName);
    }, 10);
  }

  public expandSection(sectionName: string) {
    if (this[sectionName].sectionActive === false) {
      console.log(this[sectionName]);
      this[sectionName].sectionActive = true;
      this.setWineSectionHeight(sectionName);
    }
  }

  public showWeinPopupFn(weinId: string) {
    this.weinPopupWeinId = weinId;
    this.showWeinPopup = true;
  }

  public closeWeinPopupFn() {
    this.showWeinPopup = false;
  }

  public updateCart(item) {
    this.cart = JSON.parse(localStorage.getItem('cart'));

    if (!this.cart) {
      this.cart = [];
    }

    let itemInCart = _.find(this.cart, { id: item.id}); // find the item in the cart

    if (!itemInCart) {
      this.cart.push(item); // add it if it does not exist
    } else {
      if (item.quantity <= 0) {
        _.remove(this.cart, { id: item.id }); // remove it from the cart
      } else {
        itemInCart.quantity = item.quantity; // update quantity
      }
    }

    // update the total number of items in the cart
    let cartTotalItems = 0;
    _.map(this.cart, val => {
      cartTotalItems += val.quantity;
    });
    this.cartTotalItems = cartTotalItems;

    // Put into local storage
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  public submitForm() {
    this.wineListForm.markAsDirty();

    if (this.wineListForm.valid) {
      this.sendingMail = true;
      this.mailError = false;


      let sendModel = {
        customer: this.wineListForm.value,
        language: this.language
      };

      let httpSendOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      this.http
        .post(
          "/assets/mail/winelist-mail.php",
          sendModel,
          httpSendOptions
        )
        .subscribe(
          res => {
            // If successfull, send out the customer mail
            this.http
              .post(
                "/assets/mail/winelist-mail-customer.php",
                sendModel,
                httpSendOptions
              )
              .subscribe(
                res => {
                  // console.log(res);
                  this.mailSuccess = true;
                },
                error => {
                  console.log(error);
                  this.sendingMail = false;
                  this.mailError = true;
                }
              );
          },
          error => {
            console.log(error);
            this.sendingMail = false;
            this.mailError = true;
          }
        );
    }
  }

}
