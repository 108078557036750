import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../../interfaces/api-response';

import _ from 'lodash';

@Component({
  selector: 'app-pdf-klassifikation',
  templateUrl: './pdf-klassifikation.component.html',
  styleUrls: ['./pdf-klassifikation.component.scss']
})

export class PdfKlassifikationComponent implements OnInit {
  @Input() identifier: string;
  @Input() language: string;
  @Input() classifications: any;
  @Input() categories: any;

  classification: any;
  wines: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.classification = this.getClassification(this.identifier);
    this.fetchWines(this.identifier);
  }

  private getClassification(identifier: string) {
    return _.find(this.classifications, {
      fields: {
        identifier: identifier
      }
    });
  }

  private fetchWines(classificationIdentifier: String) {
    const query = JSON.stringify({
      embedAssets: true,
      locale: this.language,
      limit: 250,
      filters: [
        {
          "wein.fields.verfuegbarkeitVal": {
            "notIn": ["archiv"]
          }
        }
      ]
    })
    return this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/${classificationIdentifier}?query=${query}`, this.httpOptions).subscribe(res => {
      this.wines = res.data;
    })
  }
}
