import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit, OnDestroy {
  showNewsletterPopup: boolean = false;
  language: string;
  langSubscription: any;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  public closeNewsletterPopup(event) {
    this.showNewsletterPopup = false;
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    // console.log(this.language);

    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
      }
    });
  }

}
