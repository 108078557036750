import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-intro-animation',
  templateUrl: './site-intro-animation.component.html',
  styleUrls: ['./site-intro-animation.component.scss']
})
export class SiteIntroAnimationComponent implements OnInit {
  animation: boolean = false;
  visible: boolean = true;
  blockedScroll: boolean = false;

  constructor() { }

  ngOnInit() {
    if (!(<any>window).disableIntroAnimation) {
      if (window.innerWidth >= 640) {
        document.querySelector("body").classList.add("no-scroll");
        this.blockedScroll = true;
      }

      setTimeout(() => {
        (<any>window).disableIntroAnimation = true;
        this.animation = true;
        setTimeout(() => {
          this.visible = false;
          if (this.blockedScroll === true) {
            document.querySelector("body").classList.remove("no-scroll");
            this.blockedScroll = false;
          }
        }, 1000);
      }, 2000);
    } else {
      this.visible = false;
      this.blockedScroll = false;
    }
  }

}
