import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-aktuelles',
  templateUrl: './aktuelles.component.html',
  styleUrls: ['./aktuelles.component.scss']
})
export class AktuellesComponent implements OnInit, OnDestroy {
  circleImageUrl: string;
  language: string;
  langSubscription: any;
  langSubscriptionMeta: any;
  news: any[] = [];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.getLanguage();
    this.setTitle();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
    this.langSubscriptionMeta.unsubscribe();
  }

  private setTitle() {
    this.langSubscriptionMeta = this.translate.get('pageTitles.aktuell').subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res});
    });
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.fetchContent();
    this.circleImageUrl = `/assets/images/site/_${this.language}-circle-aktuelles.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.fetchContent();
        this.circleImageUrl = `/assets/images/site/_${this.language}-circle-aktuelles.svg`;
      }
    });
  }

  private fetchContent() {
    // fetch news
    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/news?locale=${this.language}&embedAssets=true&limit=50`, this.httpOptions).subscribe(res => {
      this.news = [];
      this.news.push(...res.data);
    });
  }
}
