import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-site-kontakt',
  templateUrl: './site-kontakt.component.html',
  styleUrls: ['./site-kontakt.component.scss']
})
export class SiteKontaktComponent implements OnInit {
  @Output() closeContact = new EventEmitter<string>();

  showNewsletterPopup: boolean = false;
  language: string;
  langSubscription: any;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

  public setLanguage(langKey: string) {
    this.translate.use(langKey);
  }

  public closeNewsletterPopup(event) {
    this.showNewsletterPopup = false;
  }

  public closeContactFn() {
    this.closeContact.emit('close');
  }

}
