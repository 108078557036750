import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-termine',
  templateUrl: './termine.component.html',
  styleUrls: ['./termine.component.scss']
})
export class TermineComponent implements OnInit, OnDestroy {
  circleImageUrl: string;
  language: string;
  langSubscription: any;
  langSubscriptionMeta: any;
  imHofEvents = [];
  ausserHausEvents = [];
  messenEvents = [];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.getLanguage();
    this.setTitle();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.fetchContent();
    this.circleImageUrl = `/assets/images/site/_${this.language}-circle-termine.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.fetchContent();
        this.circleImageUrl = `/assets/images/site/_${this.language}-circle-termine.svg`;
      }
    });
  }

  private setTitle() {
    this.langSubscriptionMeta = this.translate.get('pageTitles.termine').subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res});
    });
  }

  private fetchContent() {
    // fetch "Im Hof"
    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/imHof?locale=${this.language}&limit=50`, this.httpOptions).subscribe(res => {
      this.imHofEvents = [];
      this.imHofEvents.push(...res.data);
    });

    // fetch "Ausser Haus"
    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/ausserHaus?locale=${this.language}&limit=50`, this.httpOptions).subscribe(res => {
      this.ausserHausEvents = [];
    this.ausserHausEvents.push(...res.data);
    });

    // fetch "Messen"
    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/messen?locale=${this.language}&limit=50`, this.httpOptions).subscribe(res => {
    this.messenEvents = [];
    this.messenEvents.push(...res.data);
    });
  }

}
