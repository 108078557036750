import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-weinarchiv',
  templateUrl: './weinarchiv.component.html',
  styleUrls: ['./weinarchiv.component.scss']
})
export class WeinarchivComponent implements OnInit, OnDestroy {

  circleImageUrl: string;
  triggerAnimation: boolean = false; // boolean is set to toggle class and trigger css animation
  language: string;
  langSubscription: any;
  langSubscriptionMeta: any;
  showWeinPopup: boolean = false;
  weinPopupWeinId: string;
  publishYears: any[] = [];
  activeYearIndex: number = 0;
  winesData: any[];

  // weine
  alltagWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    spektrum: [],
    rose: [],
  };
  individuellWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    rose: [],
  };
  unikatWeine = {
    sectionActive: false,
    weiss: [],
    weissRestsuess: [],
    rot: [],
    rose: [],
    edelsuess: [],
  };
  prickelndWeine = {
    sectionActive: false,
    secco: [],
    sekt: []
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.getLanguage();
    this.setTitle();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.fetchContent();
    this.circleImageUrl = `/assets/images/site/_${this.language}-circle-weinarchiv.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.fetchContent();
        this.circleImageUrl = `/assets/images/site/_${this.language}-circle-weinarchiv.svg`;
      }
    });
  }

  private setTitle() {
    this.langSubscriptionMeta = this.translate.get('pageTitles.weinarchiv').subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res});
    });
  }

  private fetchContent() {
    const wineQuery = encodeURIComponent(JSON.stringify({
      "limit": 1000,
      "fields": "fields(weinname,klassifikation,kategorie,jahrgang,geschmack,verfuegbarkeit,publishYear),meta(id)",
      "locale": this.language
    }));

    /*
     * Fetch weine
     */
    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/collections/weinarchiv?query=${wineQuery}`, this.httpOptions).subscribe(res => {

      // collect the years for the filter
      this.findPublishYears(res.data);
      this.winesData = res.data;
      this.winesFilter();
    });

  }

  private winesFilter() {
    // alltag
    this.alltagWeine.weiss = _.filter(this.winesData, { fields: { klassifikation: "alltag", kategorie: "weiss", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.alltagWeine.weissRestsuess = _.filter(this.winesData, { fields: { klassifikation: "alltag", kategorie: "weissRestsuess", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.alltagWeine.rot = _.filter(this.winesData, { fields: { klassifikation: "alltag", kategorie: "rot", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.alltagWeine.rose = _.filter(this.winesData, { fields: { klassifikation: "alltag", kategorie: "rose", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.alltagWeine.spektrum = _.filter(this.winesData, { fields: { klassifikation: "alltag", kategorie: "spektrum", jahrgang: this.publishYears[this.activeYearIndex]}});
    // console.log(this.alltagWeine);

    // individuell
    this.individuellWeine.weiss = _.filter(this.winesData, { fields: { klassifikation: "individuell", kategorie: "weiss", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.individuellWeine.weissRestsuess = _.filter(this.winesData, { fields: { klassifikation: "individuell", kategorie: "weissRestsuess", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.individuellWeine.rot = _.filter(this.winesData, { fields: { klassifikation: "individuell", kategorie: "rot", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.individuellWeine.rose = _.filter(this.winesData, { fields: { klassifikation: "individuell", kategorie: "rose", jahrgang: this.publishYears[this.activeYearIndex]}});
    // console.log(this.individuellWeine);

    // unikat
    this.unikatWeine.weiss = _.filter(this.winesData, { fields: { klassifikation: "unikat", kategorie: "weiss", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.unikatWeine.weissRestsuess = _.filter(this.winesData, { fields: { klassifikation: "unikat", kategorie: "weissRestsuess", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.unikatWeine.rot = _.filter(this.winesData, { fields: { klassifikation: "unikat", kategorie: "rot", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.unikatWeine.rose = _.filter(this.winesData, { fields: { klassifikation: "unikat", kategorie: "rose", jahrgang: this.publishYears[this.activeYearIndex]}});
    this.unikatWeine.edelsuess = _.filter(this.winesData, { fields: { klassifikation: "unikat", kategorie: "edelsuess", jahrgang: this.publishYears[this.activeYearIndex]}});
    // console.log(this.unikatWeine);
  }

  private findPublishYears(wines) {
    let years = _.orderBy(_.uniq(_.map(wines, 'fields.jahrgang'))).reverse();
    this.publishYears = years.filter(function (el) {
      return el != null;
    });
  }

  public inview(event) {
    // let newCircleImage: string;

    // if (event.data && event.data.name) {
    //   switch (event.data.name) {
    //     case 'weinarchiv':
    //     newCircleImage = `/assets/images/content/_${this.language}-circle-weine.svg`;
    //       break;
    //     case 'alltag':
    //     newCircleImage = `/assets/images/content/_${this.language}-circle-alltag.svg`;
    //       break;
    //     case 'individuell':
    //     newCircleImage = `/assets/images/content/_${this.language}-circle-individuell.svg`;
    //       break;
    //     case 'unikat':
    //     newCircleImage = `/assets/images/content/_${this.language}-circle-unikat.svg`;
    //       break;
    //     // missing default is intentional! keep the current image if name is undefined
    //   }

    //   if (newCircleImage !== this.circleImageUrl) {
    //     this.circleImageUrl = newCircleImage;

    //     // trigger the animation
    //     this.triggerAnimation = true;
    //     setTimeout(() => {
    //       this.triggerAnimation = false;
    //     }, 1000);
    //   }
    // }
  }

  public toggleSection(sectionName: string) {
    this[sectionName].sectionActive = !this[sectionName].sectionActive;

    let element = <HTMLElement>document.querySelector(`.wine-section.${sectionName} .wine-list`);
    if (element.style.maxHeight) {
      element.style.maxHeight = null;
    } else {
      element.style.maxHeight = element.scrollHeight + 'px';
    }
  }

  public showWeinPopupFn(weinId: string) {
    this.weinPopupWeinId = weinId;
    this.showWeinPopup = true;
  }

  public closeWeinPopupFn() {
    this.showWeinPopup = false;
  }

  public increaseYear() {
    if (this.activeYearIndex > 0) {
      this.activeYearIndex--;
      this.winesFilter();
    }
  }

  public decreaseYear() {
    if (this.activeYearIndex < this.publishYears.length - 1) {
      this.activeYearIndex++;
      this.winesFilter();
    }
  }

}
