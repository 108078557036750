import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tdot-button',
  templateUrl: './tdot-button.component.html',
  styleUrls: ['./tdot-button.component.scss']
})
export class TdotButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
