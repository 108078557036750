import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { ApiResponse } from "../interfaces/api-response";
import _ from "lodash";
import { Router, ActivatedRoute } from '@angular/router';
import slugify from 'slugify';

@Component({
  selector: 'app-weinpaket',
  templateUrl: './weinpaket.component.html',
  styleUrls: ['./weinpaket.component.scss']
})
export class WeinpaketComponent implements OnInit {
  inquiryForm: FormGroup;
  language: string;
  circleImageUrl: string;
  langSubscription: any;
  quantity: number = 0;
  cartQuantity:number = 1;
  sendingMail: boolean = false;
  mailError: boolean = false;
  mailSuccess: boolean = false;
  wineData: any[] = [];
  bundleSlug: string
  bundleData: any;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi" // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getLanguage();
    this.createForm();

    this.activatedRoute.queryParams.subscribe(params => {
      this.bundleSlug = this.activatedRoute.snapshot.params.bundleSlug;
      this.fetchContent();
    });
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.circleImageUrl = `/assets/images/site/_${
      this.language
    }-circle-weinanfrage.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.circleImageUrl = `/assets/images/site/_${
          this.language
        }-circle-weinanfrage.svg`;
      }
    });
    
  }

  private createForm() {
    this.inquiryForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      street: ["", Validators.required],
      zip: ["", Validators.required],
      city: ["", Validators.required],
      phone: [""],
      email: ["", [Validators.required, Validators.email]],
      newsletterOptIn: [false],
      message: [""]
    });
  }

  private fetchContent() {
    const query = encodeURIComponent(
      JSON.stringify({
        filters: [
          {
            "weinpaket.fields.bundleSlug": {
              equal: this.bundleSlug
            }
          }
        ],
        fields:
          "fields(name,aktionszeitraum,aktionspreis,beschreibungstext,weine,weinname,jahrgang,geschmack,inhalt,alkoholgehalt,artikelnummer,klassifikation,lage),meta(id)"
      })
    );

    this.http
      .get<ApiResponse>(
        `https://api.cmft.io/v1/weingutBraun/documents?locale=${
          this.language
        }&query=${query}&embedAssets=true&includes=1`,
        this.httpOptions
      )
      .subscribe(res => {
        _.map(res.data[0].fields.weine, (val, key) => {
          res.data[0].fields.weine[key] = _.find(res.includes.wein, { meta: { id: val.meta.id }});
          res.data[0].fields.weine[key].pdfFilename = slugify(`${res.data[0].fields.weine[key].fields.jahrgang}-${res.data[0].fields.weine[key].fields.weinname}-${res.data[0].fields.weine[key].fields.geschmack}-${res.data[0].fields.weine[key].fields.artikelnummer}`, { lower: true});;
        });

        this.bundleData = res.data[0].fields;
      });
  }

  public increaseQty() {
    ++this.cartQuantity;
  }

  public decreaseQty() {
    --this.cartQuantity;
  }

  public submitForm() {
    // console.log(this.cartQuantity, this.inquiryForm);
    this.inquiryForm.markAsDirty();

    if (this.inquiryForm.valid) {
      this.sendingMail = true;
      this.mailError = false;

      let sendModel = {
        customer: this.inquiryForm.value,
        cartQuantity: this.cartQuantity,
        wineBundleName: this.bundleData.name,
        language: 'de'
      };

      let httpSendOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      this.http
        .post(
          "/assets/mail/winebundle_inquiry-mail.php",
          // "http://formtest.local/winebundle_inquiry-mail.php",
          sendModel,
          httpSendOptions
        )
        .subscribe(
          res => {
            // If successfull, send out the customer mail
            this.http
              .post(
                "/assets/mail/winebundle_inquiry-mail-customer.php",
                // "http://formtest.local/winebundle_inquiry-mail-customer.php",
                sendModel,
                httpSendOptions
              )
              .subscribe(
                res => {
                  // console.log(res);
                  this.mailSuccess = true;
                  this.inquiryForm.disable();
                },
                error => {
                  console.log(error);
                  this.sendingMail = false;
                  this.mailError = true;
                }
              );
          },
          error => {
            console.log(error);
            this.sendingMail = false;
            this.mailError = true;
          }
        );
    }
  }

}
