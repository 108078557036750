import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import * as moment  from 'moment';

@Component({
  selector: 'app-pdf-expertise',
  templateUrl: './pdf-expertise.component.html',
  styleUrls: ['./pdf-expertise.component.scss']
})
export class PdfExpertiseComponent implements OnInit {
  documentId: string;
  language: string;
  langSubscription: any;
  wine: any;
  classification: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getDocumentId();
    document.querySelector("body").classList.add("pdf");
  }

  private getDocumentId() {
    if ((<any>window).pdf) {
      this.documentId = (<any>window).pdf.customParam1;
    } else {
      this.documentId = '1185414716542033920';
    }
    console.log(this.documentId);
    this.getLanguage();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    moment.locale(this.language);
    this.fetchContent();
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        moment.locale(this.language);
        this.fetchContent();
      }
    });
  }

  private fetchContent() {
    const query = encodeURIComponent(JSON.stringify({
      "fields": "fields(weinname,klassifikation,kategorie,jahrgang,lage,geschmack,inhalt,alkoholgehalt,allergene,weinbeschreibung,weinbewertungen,flaschenfoto,rebsorte,restzucker,saeure,verschluss,speiseempfehlung,ausbau)",
    }));

    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/documents/${this.documentId}?locale=${this.language}&query=${query}&embedAssets=true`, this.httpOptions).subscribe(res => {
      let _res = res['fields'];

      // conversions
      _res.alkoholgehalt = _res.alkoholgehalt.toLocaleString(this.language);
      if (_res.restzucker) {
        _res.restzucker = _res.restzucker.toLocaleString(this.language);
      }
      if (_res.saeure) {
        _res.saeure = _res.saeure.toLocaleString(this.language);
      }
      _res.inhalt = _res.inhalt / 1000;
      _res.inhalt = _res.inhalt.toLocaleString(this.language, { minimumFractionDigits: 1 });

      this.wine = _res;
      // console.log(this.wine);

      // fetch the classification data
      this.fetchClassification(this.wine.klassifikation);
    });
  }

  private fetchClassification(classificationId: string) {
    const query = encodeURIComponent(JSON.stringify({
      "fields": "fields",
      "filters": [
        {
          "klassifikation.fields.identifier": {
            "equal": classificationId
          }
        }
      ]
    }));

    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/documents?locale=${this.language}&query=${query}&embedAssets=true`, this.httpOptions).subscribe(res => {
      this.classification = res.data[0]['fields'];
      // console.log(this.classification);
    });
  }

}
