import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-weingut',
  templateUrl: './weingut.component.html',
  styleUrls: ['./weingut.component.scss']
})
export class WeingutComponent implements OnInit, OnDestroy {
  circleImageUrl: string;
  triggerAnimation: boolean = false; // boolean is set to toggle class and trigger css animation
  language: string;
  langSubscription: any;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  public inview(event) {
    // console.log(event.data);
    let newCircleImage: string;

    if (event.data && event.data.name) {
      switch (event.data.name) {
        case 'intro':
        newCircleImage = `/assets/images/content/circle-intro.svg`;
          break;
        case 'familie':
        newCircleImage = `/assets/images/content/_${this.language}-circle-familie.svg`;
          break;
        case 'mitarbeiter':
        newCircleImage = `/assets/images/content/_${this.language}-circle-mitarbeiter.svg`;
          break;
        case 'weingut':
        newCircleImage = `/assets/images/content/_${this.language}-circle-weingut.svg`;
          break;
        // missing default is intentional! keep the current image if name is undefined
      }

      if (newCircleImage !== this.circleImageUrl) {
        this.circleImageUrl = newCircleImage;

        // trigger the animation
        this.triggerAnimation = true;
        setTimeout(() => {
          this.triggerAnimation = false;
        }, 1000);
      }
    }
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.circleImageUrl = `/assets/images/content/circle-intro.svg`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
      this.circleImageUrl = `/assets/images/content/circle-intro.svg`;
    });
  }

}
