import { Component, OnInit, Input } from '@angular/core';

import _ from 'lodash';

@Component({
    selector: 'app-pdf-weine',
    templateUrl: './pdf-weine.component.html',
    styleUrls: ['./pdf-weine.component.scss']
})

export class PdfWeineComponent implements OnInit {
    @Input() headline: string;
    @Input() wines: any;
    @Input() category: string;

    wineList: any;
    listType: string;

    lagen = {
        ellerstadterBubeneck: 'Ellerstadter Bubeneck',
        ellerstadterKirchenstück: 'Ellerstadter Kirchenstück',
        hasslocherLeisboehl: 'Haßlocher Leisböhl',
        meckenheimerNeuberg: 'Meckenheimer Neuberg',
        meckenheimerSpielberg: 'Meckenheimer Spielberg'
    }

    constructor() { }

    ngOnInit() {
        this.listType = window['pdf'] && window['pdf']['customParam1'] ? window['pdf']['customParam1'] : 'evp';
        this.wineList = _.filter(this.wines, (wine) => {
            switch(this.listType) {
                case "evp":
                    if (wine.fields.verfuegbarkeit.anzeige.indexOf("evpliste") === -1) {
                        return false;
                    }
                    break;
                case "gastro":
                    if (wine.fields.verfuegbarkeit.anzeige.indexOf("gastroliste") === -1) {
                        return false;
                    }
                    break;
                case "handel":
                    if (wine.fields.verfuegbarkeit.anzeige.indexOf("handelsliste") === -1) {
                        return false;
                    }
                    break;
            }

            if (wine.fields.verfuegbarkeit.verfuegbarkeitVal.indexOf('leer') > -1) {
                wine.leer = true;
            } else {
                wine.leer = false;
            }

            if (wine.fields.kategorie && wine.fields.kategorie === this.category) {
                return true;
            }

            return false;
        });

        // sort
        this.wineList = _.sortBy(_.map(this.wineList, (wine) => {
            wine.sortId = wine.fields.artikelnummer.split('-')[1];
            return wine;
        }), 'sortId');

    }

}
