import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-termin-button',
  templateUrl: './termin-button.component.html',
  styleUrls: ['./termin-button.component.scss']
})
export class TerminButtonComponent implements OnInit, OnDestroy {
  termineButtonUrl: string;
  language: string;
  langSubscription: any;

  constructor(
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.getLanguage();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    // this.termineButtonUrl = `/assets/images/site/_${this.language}-circle-termine.svg`;
    this.termineButtonUrl = `/assets/images/site/termine-button-default.png`;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.termineButtonUrl = `/assets/images/site/_${this.language}-circle-termine.svg`;
      }
    });
  }

}
