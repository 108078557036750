import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import _ from 'lodash';
import slugify from 'slugify';

@Component({
  selector: 'app-site-wein-popup',
  templateUrl: './site-wein-popup.component.html',
  styleUrls: ['./site-wein-popup.component.scss']
})
export class SiteWeinPopupComponent implements OnInit, OnDestroy {
  @Input() weinId: any;
  @Output() close = new EventEmitter<string>();
  @Output() updateCart = new EventEmitter<any>();
  language: string;
  langSubscription: any;
  wineData: any;
  quantity: number;
  addedToCart: boolean = false;
  wineAvailable: boolean = false;
  archivedWine: boolean = false;
  pdfFilename: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'xWIMuJ24z6swnn6sL5oY4b3Et0m9gYGi' // Comfortable API Key
    })
  };

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.getLanguage();
    this.setScrollBlock();
    this.findQuantity();
  }

  ngOnDestroy() {
    this.unsetScrollBlock();
    this.langSubscription.unsubscribe();
  }

  private setScrollBlock() {
    document.querySelector('body').classList.add('no-scroll');
  }

  private unsetScrollBlock() {
    document.querySelector("body").classList.remove("no-scroll");
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.fetchContent();
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      if (this.language !== res.lang) {
        this.language = res.lang;
        this.fetchContent();
      }
    });
  }

  private fetchContent() {
    const query = encodeURIComponent(JSON.stringify({
      "fields": "fields(artikelnummer,weinname,klassifikation,kategorie,lage,jahrgang,geschmack,verfuegbarkeit,inhalt,alkoholgehalt,allergene,weinbeschreibung,grossflasche,flaschenfoto),meta(id)",
    }));

    this.http.get<ApiResponse>(`https://api.cmft.io/v1/weingutBraun/documents/${this.weinId}?locale=${this.language}&query=${query}&embedAssets=true`, this.httpOptions).subscribe(res => {
      this.wineData = res;

      this.pdfFilename = slugify(`${this.wineData.fields.jahrgang}-${this.wineData.fields.weinname}-${this.wineData.fields.geschmack}-${this.wineData.fields.artikelnummer}`, { lower: true});

      this.checkAvailable();
      this.checkArchived();
    });
  }

  /*
   * Check for existing quantities and update if given
   */
  private findQuantity() {
    let cart = JSON.parse(localStorage.getItem('cart'));
    let item = _.find(cart, { id: this.weinId });

    if (cart && item) {
      let _quantity = item.quantity;
      if (_quantity > 0) {
        this.quantity = _quantity;
      }
    }
  }

  private checkAvailable() {
    if (this.wineData.fields.verfuegbarkeit.verfuegbarkeitVal.indexOf('leer') === -1) {
      this.wineAvailable = true;
    }
  }

  private checkArchived() {
    if (this.wineData.fields.verfuegbarkeit.verfuegbarkeitVal.indexOf('archiv') > -1) {
      this.archivedWine = true;
    }
  }

  public closeFn() {
    this.close.emit('close');
  }

  public increaseQty() {
    if (this.wineData && this.wineData.fields.verfuegbarkeit.verfuegbarkeitVal[0] !== 'leer') {
      if (this.quantity == null) {
        this.quantity = 0;
      }
      this.quantity++;
    } 
  }

  public decreaseQty() {
    if (this.wineData && this.wineData.fields.verfuegbarkeit.verfuegbarkeitVal[0] !== 'leer') {
      if (this.quantity == null) {
        this.quantity = 0;
      }
      if (this.quantity > 0) {
        this.quantity--;
      }
    }
  }

  public addToCart() {
    if (this.quantity > 0) {
      this.addedToCart = true;
      let cartItem = {
        id: this.weinId,
        quantity: this.quantity
      }
      this.updateCart.emit(cartItem);

      setTimeout(() => {
        this.closeFn();
      }, 750);
    } else {
      this.quantity = 0;
    }
  }

}
