import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
    circleImageUrl: string;

  
    constructor() { }

  ngOnInit() {
    this.getLanguage();
  }

  private getLanguage() {
    this.circleImageUrl = `/assets/images/content/circle-intro.svg`;
  }

}
