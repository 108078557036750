import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-back-to-top',
  templateUrl: './site-back-to-top.component.html',
  styleUrls: ['./site-back-to-top.component.scss']
})
export class SiteBackToTopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

}
